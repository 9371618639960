/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@6.65.7/lib/codemirror.min.js
 * - /npm/codemirror@6.65.7/addon/hint/xml-hint.min.js
 * - /npm/codemirror@6.65.7/addon/hint/show-hint.min.js
 * - /npm/codemirror@6.65.7/addon/hint/javascript-hint.min.js
 * - /npm/codemirror@6.65.7/addon/hint/html-hint.min.js
 * - /npm/codemirror@6.65.7/addon/hint/css-hint.min.js
 * - /npm/codemirror@6.65.7/addon/lint/css-lint.min.js
 * - /npm/codemirror@6.65.7/addon/lint/html-lint.min.js
 * - /npm/codemirror@6.65.7/addon/lint/javascript-lint.min.js
 * - /npm/codemirror@6.65.7/addon/lint/lint.min.js
 * - /npm/codemirror@6.65.7/addon/lint/yaml-lint.min.js
 * - /npm/codemirror@6.65.7/addon/lint/json-lint.min.js
 * - /npm/codemirror@6.65.7/mode/javascript/javascript.min.js
 * - /npm/codemirror@6.65.7/mode/css/css.min.js
 * - /npm/codemirror@6.65.7/mode/htmlembedded/htmlembedded.min.js
 * - /npm/codemirror@6.65.7/mode/htmlmixed/htmlmixed.min.js
 * - /npm/codemirror@6.65.7/mode/xml/xml.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
